import axios from 'axios'
import config from 'config'
import { processResponseError } from './messages'
import { captureException } from '@sentry/nextjs'
import { v4 as uuid } from 'uuid'
import { AUTHORIZATION_CODE } from '../../reducers/threeds'

export const AUTHENTICATORS = {
  zap: 'zap',
  sms: 'sms',
}
export const AUTHENTICATOR_DEFAULT = AUTHENTICATORS.zap

class PaymentLinkAPI {
  static _instance = null
  #successPayment = false
  #nsuPayment = uuid()
  #advertisementId = uuid()

  #authToken = null
  #readJwt = null
  #writeJwt = null

  #source = `InvoiceJs/${config.version}`

  #linkApi = axios.create({
    baseURL: config.paymentLinkApiEndpoint,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(config.mode !== 'production' ? { Env: 'mock' } : {}),
    },
  })

  #apiV2 = axios.create({
    baseURL: config.infinitePayApiEndpoint,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  #apiAuth = axios.create({
    baseURL: config.authApiEndpoint,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  #localApi = axios.create({
    baseURL: config.localApi,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  constructor() {
    return (PaymentLinkAPI._instance ||= this)
  }
  getNSU() {
    return this.#nsuPayment
  }
  setNSU() {
    this.#nsuPayment = uuid()
  }

  mergeHeaders(headers) {
    return {
      ...headers,
      'X-Source': this.#source,
      'X-Visitor-Hash': config?.fingerprint?.visitorId,
      'X-Request-Id': this.#advertisementId,
    }
  }

  setAuthToken(token) {
    this.#authToken = token
  }
  getTokenExpirationDate(token) {
    const decoded = JSON.parse(atob(token.split('.')[1]))
    if (decoded.exp === undefined) return null
    const date = new Date(0)
    date.setUTCSeconds(decoded.exp)
    return date
  }
  isTokenExpired(token) {
    const expirationDate = this.getTokenExpirationDate(token)
    return expirationDate < new Date()
  }

  async getRead() {
    if (!this.#readJwt || this.isTokenExpired(this.#readJwt)) {
      this.#readJwt = await this.readToken(this.#authToken)
    }
    return this.#readJwt
  }
  async getWrite() {
    if (!this.#writeJwt || this.isTokenExpired(this.#writeJwt)) {
      this.#writeJwt = await this.writeToken(this.#authToken)
    }
    return this.#writeJwt
  }
  async readToken(token) {
    const data = { scopes: ['checkout/general'] }
    const response = await this.#apiAuth.post('/auth/read', data, {
      headers: this.mergeHeaders({ Authorization: token }),
    })
    return response.data.token
  }
  async writeToken(token) {
    const data = { scopes: ['legacy/link/write', 'checkout/general'] }
    const response = await this.#apiAuth.post('/auth/write', data, {
      headers: this.mergeHeaders({ Authorization: token }),
    })
    return response.data.token
  }

  async login(cellphone, authentication = 'zap') {
    const data = {
      advertisement_id: this.#advertisementId,
      user: {
        phone_number: cellphone,
        role: 'cardholder',
      },
      channel: authentication,
    }

    if (authentication === AUTHENTICATORS.zap) {
      data.channel = AUTHENTICATORS.zap
    }

    return this.#apiV2.post('/users/login_notifier', data, {
      headers: this.mergeHeaders({ 'X-Keep-Login': 'True' }),
    })
  }

  async validateToken(token, uuid, cellphone) {
    const data = {
      advertisement_id: this.#advertisementId,
      phone_number: cellphone,
      uuid,
      code: +token,
    }
    return this.#apiAuth.post('/auth/mfa', data, {
      headers: this.mergeHeaders({ Authorization: token }),
    })
  }

  async sendReceipt(payload, options = null) {
    try {
      const response = await this.#localApi.post(`/sendReceipt`, payload, options)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      throw new Error(processResponseError(err.response || err.message))
    }
  }

  async fetchMerchantByHandle(handle, options = null) {
    try {
      const response = await this.#apiV2.get(`/merchants/${handle ?? ''}/configuration`, options)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      throw new Error(processResponseError(err.response || err.message))
    }
  }

  async signIn(jwt, options = null) {
    try {
      const response = await this.#apiV2.get('/me?except=api_key', {
        headers: this.mergeHeaders({ Authorization: `Bearer ${await this.getRead()}` }),
      })
      if (response.data.user) {
        if (!response.data.user.email && options?.email) {
          const updateResponse = await this.update(
            {
              email: options.email,
            },
            {
              headers: this.mergeHeaders({ Authorization: `Bearer ${await this.getWrite()}` }),
            },
          )
        }
      }
      return response.data.user
    } catch (err) {
      captureException(err)
      if (!err.response) {
        throw err
      }
      throw new Error(processResponseError(err.response || err.message))
    }
  }

  async update(user = {}, options = null) {
    try {
      const response = await this.#apiV2.patch(
        `/me`,
        {
          user,
        },
        { ...options, headers: this.mergeHeaders(options?.headers || {}) },
      )
      return response
    } catch (err) {
      captureException(err)
      if (!err.response) {
        throw err
      }
      throw new Error(processResponseError(err.response || err.message))
    }
  }

  async pay(handle, amount, payload = {}) {
    if (this.#successPayment) {
      throw new Error('already_paid')
    }

    try {
      const response = await this.#linkApi.post(
        `/${(handle && `${handle}/${amount ?? ''}`) || ''}`,
        payload,
        {
          headers: this.mergeHeaders({ Authorization: `Bearer ${await this.getWrite()}` }),
        },
      )
      if (response.data?.data?.attributes?.authorization_code === AUTHORIZATION_CODE.ACCEPTED) {
        this.#successPayment = true
      }

      return response.data
    } catch (err) {
      if (err.response && this.canBeTriedAgain(err.response, payload)) {
        payload.retry = true
        return this.pay(handle, amount, payload)
      }
      captureException(err)
      throw new Error('unknown_error')
    }
  }
  async enrollment(handle, nsu, payload = {}) {
    try {
      const response = await this.#linkApi.post(`/${handle}/${nsu}/enrollment`, payload, {
        headers: this.mergeHeaders({ Authorization: `Bearer ${await this.getWrite()}` }),
      })
      return response.data
    } catch (err) {
      if (err.response && this.canBeTriedAgain(err.response, payload)) {
        payload.retry = true
        return this.enrollment(handle, nsu, payload)
      }
      throw err
    }
  }
  async validate(handle, nsu, payload = {}) {
    try {
      const response = await this.#linkApi.post(`/${handle}/${nsu}/validate`, payload, {
        headers: this.mergeHeaders({ Authorization: `Bearer ${await this.getWrite()}` }),
      })
      return response.data
    } catch (err) {
      if (err.response && this.canBeTriedAgain(err.response, payload)) {
        payload.retry = true
        return this.validate(handle, nsu, payload)
      }
      throw err
    }
  }
  canBeTriedAgain(response, payload) {
    return response?.status !== 422 && !payload.retry
  }
}

export default new PaymentLinkAPI()
