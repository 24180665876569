import { createSlice } from '@reduxjs/toolkit'
import config from 'config'

const redirectReceiptSlice = createSlice({
  name: 'redirect',
  initialState: {
    active: false,
  },
  reducers: {
    redirectToReceipt(state, action) {
      const newReceiptUrl = `${config.receiptsURL}/${action.payload}`
      window.location.href = newReceiptUrl
      state.active = true
    },
  },
})

const { actions, reducer } = redirectReceiptSlice

export const selectRedirectReceipt = (state) => state.redirect

export const { redirectToReceipt } = actions

export default reducer
