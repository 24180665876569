import { combineReducers } from 'redux'
import paymentsReducer from './payments'
import zipcodeReducer from './zipcode'
import receiptsReducer from './receipts'
import sessionReducer from './session'
import threedsReducer from './threeds'
import redirectReducer from './redirectReceipt'

const rootReducer = combineReducers({
  session: sessionReducer,
  payments: paymentsReducer,
  threeds: threedsReducer,
  zipcode: zipcodeReducer,
  receipt: receiptsReducer,
  redirect: redirectReducer,
})

export default rootReducer
