import { createContext, useContext, useState, useEffect } from 'react'
import currency from 'currency-formatter'
import { Payment_Methods } from 'utils/payableWith'
import { useSelector } from 'react-redux'
import { selectPayments } from 'reducers/payments'

const PaymentContext = createContext()

const parseAmont = (amount, hard_limit_amount) => {
  try {
    amount =
      amount &&
      amount
        .replace(/\./gi, ',')
        .split(',')
        .reduce((prev, current, index, array) => {
          return prev + (index === array.length - 1 ? ',' : '') + current
        })
    const cleanAmount = /\d+(,\d\d)?$/gi.test(amount)
    const parsedAmount = currency.unformat(amount, { code: 'BRL', precision: 2 })
    const validAmount =
      cleanAmount &&
      !isNaN(parseFloat(amount)) &&
      parsedAmount >= 1 &&
      parsedAmount <= hard_limit_amount - 1
    const amountValue = parsedAmount.toFixed(2).replace('.', ',')
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(parsedAmount)
    return {
      invalid_amount: !validAmount && cleanAmount,
      amount: validAmount && {
        value: amountValue,
        formattedValue: `R$ ${amountValue}`,
        valueAsNumber: parsedAmount,
      },
    }
  } catch (err) {}
}

const parseAmountFromInvoice = (invoiceData, payments) => {
  let parsedValue
  if (!payments.active) {
    parsedValue = invoiceData.info?.amount
  } else {
    const installments = invoiceData?.dynamicParams?.installments
    if (installments && installments >= 0 && installments <= 12) {
      parsedValue =
        invoiceData.value_with_fees?.capture_methods?.credit_card[installments - 1].total_value
    } else if(invoiceData.value_with_fees?.capture_methods?.credit_card) {
      parsedValue = invoiceData.value_with_fees?.capture_methods?.credit_card[0].total_value
    }
  }

  const amountValue = parsedValue?.toFixed(2).replace('.', ',')
  const formattedValue = currency.format(parsedValue, { code: 'BRL', precision: 2 })
  return {
    invalid_amount: false,
    amount: {
      value: amountValue,
      formattedValue: formattedValue,
      valueAsNumber: parsedValue,
    },
  }
}

export function PaymentContextProvider({ children, values }) {
  const [amount, setAmount] = useState({})
  const [invoiceData, setInvoiceData] = useState({
    ...values.invoiceData,
  })
  const [maxInstallments, setMaxInstallments] = useState(1)
  const { params } = values
  const payments = useSelector(selectPayments)
  // Fetch data
  useEffect(() => {
    if (invoiceData) {
      setAmount(parseAmountFromInvoice(invoiceData, payments))
    }
  }, [params?.amount, values?.userData?.hard_limit_amount, invoiceData, payments])
  useEffect(() => {
    setMaxInstallments(
      invoiceData.value_with_fees?.capture_methods?.credit_card
        ? invoiceData.value_with_fees?.capture_methods?.credit_card.length
        : 1,
    )
  }, [invoiceData.value_with_fees?.capture_methods])
  const userData = values.userData ? { ...values.userData, ...amount } : null
  return (
    <PaymentContext.Provider
      value={{ ...values, invoiceData, userData, maxInstallments, setAmount, setInvoiceData }}
    >
      {children}
    </PaymentContext.Provider>
  )
}

export function usePaymentContext() {
  return useContext(PaymentContext)
}

export default PaymentContext
