export const Payment_Methods = {
  credit_card: 'credit_card',
  pix: 'pix',
  bank_slip: 'bank_slip',
}

const payableWith = (invoiceData) => {
  return invoiceData?.info?.payable_with || [Payment_Methods.credit_card]
}

export default payableWith
