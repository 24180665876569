import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import cardValidator from 'card-validator'
import behavioural from 'libs/behavioural'
import linkApi, { AUTHENTICATOR_DEFAULT } from 'libs/infinitepay/link'
import { signIn } from './session'
import { captureException } from '@sentry/nextjs'
import { AUTHORIZATION_CODE, THREEDS_EVENT, showFormPost } from './threeds'
import { redirectToReceipt } from './redirectReceipt'

export const createPayment = createAsyncThunk(
  'payments/createPayment',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const { handle, router, amount, rudderStack, ...paymentPayload } = payload
    const session = getState().session
    const phoneNumber = paymentPayload.billing_details.phone_number
    let user = session.user
    let response
    if (!user || user.phone_number !== phoneNumber) {
      try {
        response = await dispatch(
          signIn({
            phoneNumber: phoneNumber,
            email: paymentPayload.billing_details.email,
            authenticator: AUTHENTICATOR_DEFAULT,
            rudderStack,
          }),
        )
        const { payload } = response
        const { user: resultAsUser } = payload
        if (resultAsUser) {
          user = resultAsUser
        } else {
          if (typeof payload !== 'string') {
            return {
              signing: true,
              error: null,
            }
          }
          return rejectWithValue({
            signing: true,
            error: payload,
          })
        }
      } catch (err) {
        if (!err.response) {
          return rejectWithValue(err.message)
        } else {
          return rejectWithValue(err.response.data || err.message)
        }
      }
    }

    const userHandle = user?.handle
    try {
      if (userHandle === handle) {
        throw new Error('501')
      }
      response = await linkApi.pay(handle, amount, paymentPayload)
      const attrs = response.data?.attributes
      const code = attrs?.authorization_code || undefined

      if (response.data.invoice) {
        router.replace(
          router.asPath.replace(/^\/(.+)\/(.+)\/(.+)/, `/$1/${response.data.invoice.slug}/`),
        )
      }
      const receipt = getReceipt(user, paymentPayload, attrs, amount, handle, response)
      if (code.toUpperCase() === AUTHORIZATION_CODE.THREEDS && attrs.three_ds) {
        behavioural.finish()

        let params = {
          transaction: { nsu: attrs?.nsu, receipt: receipt, handle: handle },
        }
        if (attrs.three_ds.event === THREEDS_EVENT.AUTH_REQUEST) {
          params.form = {
            action: attrs.three_ds.device_data_collection_url,
            jwt: attrs.three_ds.access_token,
          }
        } else if (attrs.three_ds.event === THREEDS_EVENT.ENROLLMENT) {
          params.challenge = {
            source: attrs.three_ds.challenge_url,
            width: attrs.three_ds.width,
            height: attrs.three_ds.height,
            jwt: attrs.three_ds.access_token,
          }
        }

        const check = await dispatch(showFormPost(params))
      } else if (code !== AUTHORIZATION_CODE.ACCEPTED) {
        behavioural.addFailedAttempts()
        linkApi.setNSU()
        throw new Error(code)
      } else {
        behavioural.finish()
        dispatch(hideForm())
        dispatch(redirectToReceipt(receipt?.transactionId))
      }
      return response.data || response
    } catch (err) {
      captureException(err)
      if (!err.response) {
        return rejectWithValue(err.message)
      } else {
        return rejectWithValue(err?.response.data || err?.message)
      }
    }
  },
)

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    fetching: false,
    active: false,
    methodIndex: 0,
    signing: null,
    transaction: null,
    error: null,
  },
  reducers: {
    showForm(state, action) {
      state.active = true
    },
    hideForm(state) {
      state.active = false
    },
    setMethodIndex(state, action) {
      state.methodIndex = action.payload
    },
  },
  extraReducers: {
    [createPayment.pending]: (state, action) => {
      state.fetching = true
      state.signing = false
      state.error = null
    },
    [createPayment.fulfilled]: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.error = null
      state.signing = !!payload?.signing
      state.transaction = !state.signing ? action.payload : null
    },
    [createPayment.rejected]: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.transaction = null
      state.signing = !!payload?.signing
      state.error = payload?.error || payload
    },
  },
})

const { actions, reducer } = paymentsSlice

export const selectPayments = (state) => state.payments

export const { showForm, hideForm, setMethodIndex } = actions

export default reducer
function getReceipt(user, paymentPayload, attrs, amount, handle, response) {
  const fromHandle =
    user.handle ||
    user.email ||
    user.name ||
    paymentPayload.billing_details.email ||
    paymentPayload.billing_details.name
  const name = (user.merchant || user.cardholder)?.name || fromHandle
  const cardType = cardValidator.number(paymentPayload.card?.card_number || '')?.card?.type
  const date = new Date(attrs?.created_at)
  const params = {
    name: name,
    email: paymentPayload.billing_details.email || user.email,
    amount: amount,
    from: fromHandle,
    cardType: cardType,
    capture_method: 'credit_card',
    handle: handle,
    transactionId: response.data?.id || attrs?.nsu,
    date: format(date, 'dd MMM yyyy - HH:mm', { locale: ptBR }),
    installments: paymentPayload.payment.installments,
    cardNumberTampered: paymentPayload.card?.card_number?.substr(-4) || '',
  }

  return params
}
