import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { captureException } from '@sentry/nextjs'
import axios from 'axios'
import m from '../components/ui/form/PaymentForm/messages'

export const fetchAddressByZipcode = createAsyncThunk(
  'zipcode/fetchAddressByZipcode',
  async (zipcode, { rejectWithValue }) => {
    try {
      if (/^[0-9]{8}$/.test(zipcode) === false) {
        throw new Error(m.billing_zip_code.invalid)
      }
      const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json`)
      if (response.data.erro) {
        throw new Error(m.billing_zip_code.invalid)
      }
      return response.data
    } catch (err) {
      captureException(err)
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.toString() || err?.response?.data)
    }
  },
)

const zipCodeSlice = createSlice({
  name: 'zipcode',
  initialState: {
    fetching: false,
    error: null,
    searched: false,
    address: null,
  },
  reducers: {},
  extraReducers: {
    [fetchAddressByZipcode.pending]: (state, action) => {
      state.fetching = true
      state.error = null
    },
    [fetchAddressByZipcode.fulfilled]: (state, action) => {
      state.fetching = false
      state.address = action.payload
      state.searched = true
      state.error = null
    },
    [fetchAddressByZipcode.rejected]: (state, action) => {
      state.fetching = false
      state.address = null
      state.searched = true
      if (action.payload) {
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error.message
      }
    },
  },
})

const { reducer } = zipCodeSlice

export const selectZipCodeSearch = (state) => state.zipcode

export default reducer
