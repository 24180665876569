import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { v4 as uuid } from 'uuid'

class Config {
  static _instance = null

  #sessionId

  defaultMaxInstallments = 12

  defaultLimitAmount = 10001

  env = process.env.NODE_ENV

  version = process.env.COMMIT_HASH || 'no-version'

  mode = process.env.MODE

  localApi = '/api'

  paymentLinkApiEndpoint = process.env.PAYMENT_LINK_ENDPOINT

  infinitePayApiEndpoint = process.env.INFINITEPAY_API_ENDPOINT

  authApiEndpoint = process.env.AUTH_API_ENDPOINT

  receiptsURL = process.env.RECEIPTS_URL

  paymentLinkUI = process.env.PAYMENT_LINK_UI

  invoiceApiEndpoint = process.env.INVOICE_API

  cyberSourceOrgId = process.env.CYBERSOURCE_ORG_ID

  cyberSourceMerchantId = process.env.CYBERSOURCE_MERCHANT_ID

  writeKey = process.env.RUDDERSTACK_WRITE_KEY || ''
  dataPlaneUrl = process.env.RUDDERSTACK_DATA_PLANE_URL || ''

  infinitePayLink =
    'https://www.infinitepay.io/link-de-pagamento/?utm_source=PAYMENTLINK&utm_medium=WEBPAGE&utm_campaign=PAYMENTLINKWEB'
  infinitePayLinkBanner = {
    desktop:
      'https://www.infinitepay.io/link-de-pagamento/?utm_source=PAYMENTLINK&utm_medium=WEBPAGE&utm_campaign=BANNERPRINCIPAL',
    mobile:
      'https://www.infinitepay.io/link-de-pagamento/?utm_source=PAYMENTLINK&utm_medium=WEBPAGE&utm_campaign=BANNERMOBILE',
    receipt:
      'https://www.infinitepay.io/link-de-pagamento/?utm_source=PAYMENTLINK&utm_medium=WEBPAGE&utm_campaign=BANNERRECEIPT',
    home: 'https://www.infinitepay.io/link-de-pagamento/?utm_source=PAYMENTLINK&utm_medium=WEBPAGE&utm_campaign=BANNERHOMEPAYMENTLINK',
  }

  deeplink = {
    androidScheme:
      'intent://pay.infinitepay.io/:path#Intent;scheme=https;package=io.cloudwalk.infinitepaydash;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dio.cloudwalk.infinitepaydash;end',
    iosScheme: 'infinitepaydash://pay/:path',
    appStore: 'https://apps.apple.com/app/id1506929912',
    iosAppStore: 'https://apps.apple.com/app/id1506929912',
    androidPackageName: 'io.cloudwalk.infinitepaydash',
    googlePlay: 'https://play.google.com/store/apps/details?id=io.cloudwalk.infinitepaydash',
    oneLink: 'https://infinitepay.onelink.me/JmLI/9i585p6o',
  }

  UF = {
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins',
  }

  constructor() {
    if (!Config._instance) {
      Config._instance ||= this
      if (typeof window !== 'undefined') {
        const fpPromise = FingerprintJS.load()

        ;(async () => {
          const fp = await fpPromise
          const result = await fp.get()
          this.fingerprint = result
        })()
      }
    }
    return Config._instance
  }

  get sessionId() {
    return this.#sessionId
  }

  createSessionID(source = uuid()) {
    this.#sessionId = source
    return this.#sessionId
  }
}

module.exports = new Config()
