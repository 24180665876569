import Head from 'next/head'
import { Box } from '@material-ui/core'

const Layout = ({ children, ...props }) => {
  return (
    <>
      <Head>
        <link rel="prefetch" href="/static/fonts/CeraPro-Regular.woff2" as="font" crossOrigin="" />
        <link rel="prefetch" href="/static/fonts/CeraPro-Bold.woff2" as="font" crossOrigin="" />
        <link rel="prefetch" href="/static/fonts/CeraPro-Italic.woff2" as="font" crossOrigin="" />
        <link rel="prefetch" href="/static/fonts/CeraPro-Medium.woff2" as="font" crossOrigin="" />
      </Head>
      <Box component="main" display="flex" flexDirection="column">
        {children}
      </Box>
    </>
  )
}

export default Layout
