/* globals DocumentTouch */
const DeviceHelper = require('node-device-detector/helper')

let isTouch = false

const testTouchDevice = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  var mq = function (query) {
    return window.matchMedia(query).matches
  }
  if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
    return true
  }
  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}
if (typeof window !== 'undefined') {
  require('matchmedia-polyfill')
  isTouch = testTouchDevice()
}

function DetectionHelper(detector = { device: {}, os: {}, client: {} }) {
  try {
    const isMobile = DeviceHelper.isMobile(detector)
    const isTablet = DeviceHelper.isTablet(detector)
    return {
      mobile: isMobile,
      tablet: isTablet,
      app: DeviceHelper.isApp(detector),
      mobileApp: DeviceHelper.isMobileApp(detector),
      desktopApp: DeviceHelper.isDesktopApp(detector),
      desktop: DeviceHelper.isDesktop(detector),
      device: isMobile || isTablet,
      browser: detector?.client?.type === 'browser',
      wearable: DeviceHelper.isWearable(detector),
      touch: isTouch,
      detector,
    }
  } catch (err) {
    return {
      mobile: false,
      tablet: false,
      app: false,
      mobileApp: false,
      desktopApp: false,
      desktop: false,
      device: false,
      browser: false,
      wearable: false,
      touch: false,
    }
  }
}

export default DetectionHelper
