import { createTheme } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MaskedInputBase from 'components/ui/form/MaskedInputBase'
import ErrorIcon from 'assets/system-alert.svg'

const baseTheme = {
  breakpoints: {
    values: {
      xs: 0,
      xx: 375,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    button: {
      textTransform: '',
    },
    fontFamily: [
      'CeraPro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      dark: '#000',
      main: '#000',
      light: '#4757f7',
      alt: '#0057F0',
    },
    error: {
      dark: '#ac182c',
      main: '#F7233F',
      light: '#f84f65',
    },
    background: {
      default: '#121212',
    },
    text: {
      light: 'rgb(255, 255, 255)',
    },
    input: {
      background: '#F5F5F5',
      highlight: '#e8f0fe',
    },
  },
  input: {
    placeholderHidden: {
      opacity: '0.5',
    },
  },
}

const theme = createTheme({
  props: {
    MuiTextField: {
      variant: 'outlined',
      InputProps: {
        inputComponent: MaskedInputBase,
      },
    },
    MuiOutlinedInput: {
      notched: false,
    },
    MuiSelect: {
      variant: 'outlined',
      IconComponent: KeyboardArrowDownIcon,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: 48,
        borderRadius: 48,
        transform: 'scale(1)',
        transition: 'transform .1s ease-out',
        '&:hover': {
          transform: 'scale(0.94)',
        },
      },
      contained: {
        '&$disabled, &[aria-invalid=true]': {
          color: baseTheme.palette.text.light,
          background: '#9F9F9F',
          '&:hover': {},
        },
      },
    },
    MuiInputBase: {
      input: {
        'label[data-shrink=false] + $formControl &': {
          '&::-webkit-input-placeholder': {
            opacity: baseTheme.input.placeholderHidden,
          },
          '&:-ms-input-placeholder': {
            opacity: baseTheme.input.placeholderHidden,
          },
          '&::-moz-placeholder': {
            opacity: baseTheme.input.placeholderHidden,
          },
          '&:-moz-placeholder': {
            opacity: baseTheme.input.placeholderHidden,
          },
          '&::placeholder': {
            opacity: baseTheme.input.placeholderHidden,
          },
        },
        '&[aria-invalid=false]': {
          background: baseTheme.palette.input.highlight,
        },
      },
      root: {
        '&$adornedEnd': {
          backgroundColor: baseTheme.palette.input.background,
          '&[aria-invalid=false]': {
            background: baseTheme.palette.input.highlight,
          },
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          display: 'flex',
          alignItems: 'center',
          '&:before': {
            content: "''",
            display: 'inline-block',
            background: `transparent url("${ErrorIcon}") no-repeat center center / contain`,
            fontSize: '1.3333333333em',
            width: '1em',
            height: '1em',
            flex: '0 0 1em',
            marginRight: '0.25em',
          },
          '&$contained': {
            marginLeft: '0.75em',
            lineHeight: 1.3,
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: grey[900],
        fontWeight: 500,
      },
      formControl: {
        fontSize: '0.875rem',
      },
      outlined: {
        position: 'relative',
        margin: '0 0 0 0.5rem',
        '&, &$focused, &$shrink': {
          transform: 'translate(0, 0) scale(1)',
          marginBottom: '0.5em',
          fontSize: '1em',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0.5rem',
      },
      input: {
        padding: '1rem',
        borderRadius: '0.5rem',
        backgroundColor: baseTheme.palette.input.background,
      },
      notchedOutline: {
        borderColor: 'transparent',
      },
    },
    MuiSelect: {
      root: {
        '&$outlined': {
          borderRadius: '0.5rem',
        },
        '&[data-selected]:not([data-selected="default"])[aria-invalid=false]': {
          background: baseTheme.palette.input.highlight,
        },
        '&[data-selected="default"][aria-invalid=false]': {
          background: baseTheme.palette.input.background,
          color: 'rgba(33,33,33,0.5)',
        },
      },
    },
    MuiMenuItem: {
      root: {
        'font-feature-settings': "'tnum' on, 'lnum' on",
      },
    },
  },
  ...baseTheme,
})

export default theme
