import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { captureException } from '@sentry/nextjs'

import linkApi from 'libs/infinitepay/link'

export const signIn = createAsyncThunk(
  'session/signIn',
  async (
    { phoneNumber, name, email, authenticator, rudderStack },
    { getState, rejectWithValue },
  ) => {
    try {
      authenticator = authenticator || AUTHENTICATOR_DEFAULT
      rudderStack.track(`Invoice - Token[${authenticator}] - Start`)
      const session = getState().session
      const loggedUser = session.user
      if (loggedUser && loggedUser.phoneNumber === phoneNumber) {
        return {
          user: loggedUser,
        }
      } else {
        const auth = await linkApi.login(phoneNumber, authenticator)
        if (auth.data && !auth.data.errors) {
          return { auth: { ...auth.data, authenticator }, extraData: { name, email, phoneNumber } }
        }
        return rejectWithValue(JSON.stringify(auth.data.errors))
      }
    } catch (err) {
      captureException(err)
      if (err.response && err.response.status === 429) {
        return rejectWithValue(
          `Você já realizou muitas tentativas, por favor aguarde e tente novamente`,
        )
      }
      if (err.response) {
        return rejectWithValue(
          `Erro ao tentar validar seu telefone, por favor tente novamente mais tarde`,
        )
      }
      return rejectWithValue(err?.message)
    }
  },
)

export const confirmToken = createAsyncThunk(
  'session/confirmToken',
  async ({ token, name, uuid, email, phoneNumber, rudderStack }, { getState, rejectWithValue }) => {
    try {
      const {
        confirmation: { authenticator },
      } = getState().session
      rudderStack.track(`Invoice - Token[${authenticator}] - Check`)
      const jwtResponse = await linkApi.validateToken(token, uuid, phoneNumber)
      if (typeof jwtResponse.data === 'object') {
        linkApi.setAuthToken(jwtResponse.data.token)
        rudderStack.track(`Invoice - Token[${authenticator}] - Validated`)
        const response = await linkApi.signIn({
          name,
          email,
          phoneNumber,
        })
        return {
          user: response,
        }
      } else {
        throw new Error(
          `O código SMS está inválido ou expirado. Reenvie o código de verificação para tentar novamente.`,
        )
      }
    } catch (err) {
      captureException(err)
      if (err.response && err.response.status === 401) {
        return rejectWithValue(
          `O código SMS está inválido ou expirado. Reenvie o código de verificação para tentar novamente.`,
        )
      }
      if (err.response && err.response.status === 429) {
        return rejectWithValue(
          `Você já realizou muitas tentativas, por favor aguarde e tente novamente`,
        )
      }
      if (err.response) {
        return rejectWithValue(
          `Erro ao tentar validar seu telefone, por favor tente novamente mais tarde`,
        )
      }
      return rejectWithValue(err?.message)
    }
  },
)

export const reToken = createAsyncThunk(
  'session/reToken',
  async ({ phoneNumber, email, authenticator }, { rejectWithValue }) => {
    try {
      authenticator = authenticator || AUTHENTICATOR_DEFAULT
      const auth = await linkApi.login(phoneNumber, authenticator)
      if (auth.data && !auth.data.errors) {
        return { auth: { ...auth.data, authenticator }, extraData: { name, email, phoneNumber } }
      }
      return rejectWithValue(JSON.stringify(auth.data.errors))
    } catch (err) {
      captureException(err)
      if (err.response && err.response.status === 429) {
        return rejectWithValue(
          `Você já realizou muitas tentativas, por favor aguarde e tente novamente`,
        )
      }
      if (err.response) {
        return rejectWithValue(
          `Erro ao tentar validar seu telefone, por favor tente novamente mais tarde`,
        )
      }
      return rejectWithValue(err?.message)
    }
  },
)

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    fetching: false,
    confirmation: null,
    user: null,
    receiver: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [signIn.pending]: (state, action) => {
      state.fetching = true
      state.user = null
      state.extraData = null
      state.error = null
    },
    [signIn.fulfilled]: (state, action) => {
      const { extraData, user, auth } = action.payload || {}
      state.error = null
      state.fetching = false
      state.extraData = extraData
      if (user) {
        state.confirmation = null
        state.user = user
      } else {
        state.confirmation = auth
      }
    },
    [signIn.rejected]: (state, action) => {
      state.fetching = false
      state.confirmation = null
      state.error = action.payload
    },
    [reToken.pending]: (state, action) => {
      state.fetching = true
      state.error = null
    },
    [reToken.fulfilled]: (state, action) => {
      const { auth, extraData } = action.payload || {}
      state.error = null
      state.fetching = false
      state.extraData = extraData
      state.confirmation = auth
    },
    [reToken.rejected]: (state, action) => {
      state.fetching = false
      state.error = action.payload?.message || action.payload
    },
    [confirmToken.pending]: (state, action) => {
      state.fetching = true
      state.error = null
    },
    [confirmToken.fulfilled]: (state, action) => {
      const { user } = action.payload || {}
      state.fetching = false
      state.confirmation = null
      if (user) {
        state.user = user
        state.error = null
      } else {
        state.error = user?.error || action.payload
      }
    },
    [confirmToken.rejected]: (state, action) => {
      state.fetching = false
      state.error = action.payload?.message || action.payload
    },
  },
})

const { reducer } = sessionSlice

export const selectSession = (state) => state.session

export default reducer
