import axios from 'axios'
import config from 'config'
// import devtools from 'devtools-detect'
import Mus from './mus'

class BehaviouralData {
  static _instance = null

  #timer = {
    start: null,
    end: null,
    elapsed: 0,
  }

  #devtools = {
    open_count: 0,
    is_open: false,
  }

  #window = {
    changed: false,
    changes_count: 0,
    time_spent: 0,
    timer: null,
  }

  #field_actions = {
    copy: {},
    paste: {},
    autocomplete: {},
    filling: {},
  }

  #attempts = {
    count: 0,
    first_attempts: {},
  }

  #mus = null
  #mouse_log = null
  #cleanWindowObserver = null
  #privateMode = null

  constructor() {
    const instance = (BehaviouralData._instance ||= this)
    if (typeof window !== 'undefined') {
      this.#mus = new Mus()
      this.#mus.record()
      this.#timer.start = new Date().getTime()
      this.#cleanWindowObserver = this.#createWindowObserver()
      this.#isPrivateMode().then((response) => {
        this.#privateMode = response
      })
      // if (devtools.isOpen) {
      //   this.#devtools.open_count += 1
      //   this.#devtools.is_open = true
      // }
      // window.addEventListener('devtoolschange', this.#_didOpenDevTools.bind(this))
    }
    return instance
  }

  addFieldOperation(type, fieldName) {
    const operation = (this.#field_actions[type] ||= {})
    operation[fieldName] ||= 0
    operation[fieldName]++
  }

  startFillingField(fieldName) {
    const fillingData = (this.#field_actions.filling[fieldName] ||= {
      timer: null,
      spent: 0,
    })
    fillingData.timer = new Date().getTime()
  }

  stopFillingField(fieldName) {
    const fillingData = this.#field_actions.filling[fieldName]
    if (fillingData) {
      fillingData.spent = (new Date().getTime() - fillingData.timer) / 1000
      delete fillingData.timer
    }
  }

  addFailedAttempts() {
    this.#attempts.count++
  }

  getData() {
    this.#mouse_log = this.#mus.getData()
    delete this.#mouse_log.timeElapsed
    this.#timer.end = new Date().getTime()
    this.#timer.elapsed = this.#timer.end - this.#timer.start
    this.#cleanWindowObserver()
    return this.#results()
  }

  finish() {
    this.#mus.stop()
    this.#cleanWindowObserver()
    return this.getData()
  }

  #results() {
    return {
      behavioural: {
        total_time: this.#timer.elapsed / 1000,
        private_mode: this.#privateMode,
        field_actions: {
          ...this.#field_actions,
        },
        // devtools: {
        //   ...this.#devtools,
        // },
        mouse_log: {
          ...this.#mouse_log,
        },
        attempts: {
          ...this.#attempts,
        },
      },
    }
  }

  // #_didOpenDevTools(event) {
  //   this.#devtools.is_open = event.detail.isOpen
  //   if (event.detail.isOpen) {
  //     this.#devtools.open_count++
  //   }
  // }

  #createWindowObserver() {
    var hidden, visibilityChange
    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden'
      visibilityChange = 'visibilitychange'
    } else if (typeof document.mozHidden !== 'undefined') {
      hidden = 'mozHidden'
      visibilityChange = 'mozvisibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden'
      visibilityChange = 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
    }

    const _handleVisibilityChange = () => {
      if (document[hidden]) {
        this.#window.timer = new Date().getTime()
        this.#window.changed = true
        this.#window.changes_count++
      } else {
        this.#window.time_spent += new Date().getTime() - this.#window.timer
      }
    }

    document.addEventListener(visibilityChange, _handleVisibilityChange, false)

    return () => {
      document.removeEventListener(visibilityChange, _handleVisibilityChange, false)
    }
  }

  #isPrivateMode() {
    return new Promise(function detect(resolve) {
      var yes = function () {
        resolve(true)
      } // is in private mode
      var not = function () {
        resolve(false)
      } // not in private mode

      function isIncognito(callback) {
        var fs = window.RequestFileSystem || window.webkitRequestFileSystem

        if (!fs) {
          callback(false)
        } else {
          fs(window.TEMPORARY, 100, callback.bind(undefined, false), callback.bind(undefined, true))
        }
      }

      function detectChromeOpera() {
        var isChromeOpera =
          /(?=.*(opera|chrome)).*/i.test(navigator.userAgent) &&
          navigator.storage &&
          navigator.storage.estimate
        if (isChromeOpera) {
          isIncognito((is) => {
            if (is) {
              yes()
            } else {
              navigator.storage.estimate().then(function (data) {
                return data.quota < 900000000 ? yes() : not()
              })
            }
          })
        }
        return !!isChromeOpera
      }

      function detectFirefox() {
        var isMozillaFirefox = 'MozAppearance' in document.documentElement.style
        if (isMozillaFirefox) {
          if (indexedDB == null) yes()
          else {
            var db = indexedDB.open('inPrivate')
            db.onsuccess = not
            db.onerror = yes
          }
        }
        return isMozillaFirefox
      }

      function detectSafari() {
        var isSafari = navigator.userAgent.match(/Version\/([0-9\._]+).*Safari/)
        if (isSafari) {
          var testLocalStorage = function () {
            try {
              if (localStorage.length) not()
              else {
                localStorage.setItem('inPrivate', '0')
                localStorage.removeItem('inPrivate')
                not()
              }
            } catch (_) {
              // Safari only enables cookie in private mode
              // if cookie is disabled, then all client side storage is disabled
              // if all client side storage is disabled, then there is no point
              // in using private mode
              navigator.cookieEnabled ? yes() : not()
            }
            return true
          }

          var version = parseInt(isSafari[1], 10)
          if (version < 11) return testLocalStorage()
          try {
            window.openDatabase(null, null, null, null)
            not()
          } catch (_) {
            yes()
          }
        }
        return !!isSafari
      }

      function detectEdgeIE10() {
        var isEdgeIE10 = !window.indexedDB && (window.PointerEvent || window.MSPointerEvent)
        if (isEdgeIE10) yes()
        return !!isEdgeIE10
      }

      // when a browser is detected, it runs tests for that browser
      // and skips pointless testing for other browsers.
      if (detectChromeOpera()) return
      if (detectFirefox()) return
      if (detectSafari()) return
      if (detectEdgeIE10()) return

      // default navigation mode
      return not()
    })
  }
}

export default new BehaviouralData()
