import 'globals.css'
import App from 'next/app'
import Head from 'next/head'
import { withRouter } from 'next/router'
import Script from 'next/script'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Layout from 'components/Layout'
import { PaymentContextProvider } from 'context/PaymentContext'
import DeviceDetector from 'device-detector-js'
import DetectionHelper from 'utils/DeviceDetection'
import theme from 'theme'
import config from 'config'
import withRedux from 'next-redux-wrapper'
import createStore from 'store/createStore'
import { Provider as ReduxProvider } from 'react-redux'

const { GTM_ID } = process.env
const { CLARITY_ID } = process.env

class InfinitePaymentLinkApp extends App {
  static async getInitialProps(_) {
    return {}
  }
  constructor(props) {
    super(props)

    const deviceDetector = new DeviceDetector()
    const userAgent =
      typeof window !== 'undefined'
        ? window.navigator.userAgent
        : 'Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; compatible; Googlebot/2.1; +http://www.google.com/bot.html) Chrome/W.X.Y.Z Safari/537.36'
    const userHardware = deviceDetector.parse(userAgent)
    const detector = DetectionHelper(userHardware)
    const { cyberSourceOrgId, cyberSourceMerchantId } = config
    const cyberSourceUrl = `https://authorizer-data.infinitepay.io/fp/tags.js?org_id=${cyberSourceOrgId}&session_id=${cyberSourceMerchantId}${config.createSessionID()}`

    this.state = {
      mounted: false,
      userHardware,
      cyberSourceUrl,
      sessionId: config.sessionId,
      deviceDetection: detector,
    }
  }

  componentDidMount() {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }

  #onAuthChanged = (user) => {
    if (user) {
      this.setState({
        signedUser: user ?? undefined,
      })
    }
  }

  render() {
    const { Component, pageProps, router, store } = this.props
    const { cyberSourceUrl, sessionId } = this.state
    const params = router?.query || { handle: '', amount: null }
    const identifierText = pageProps?.invoiceData?.info?.reference
      ? `Cobrança referente ao ${pageProps?.invoiceData?.info?.reference}. `
      : ``
    const meta = {
      title:
        pageProps?.userData?.id && pageProps?.invoiceData?.success
          ? `Cobrança de $${pageProps?.userData?.id}`
          : `Desculpe, mas não encontramos a sua cobrança`,
      image:
        pageProps?.userData?.id && pageProps?.invoiceData?.success
          ? `${config.paymentLinkUI}/static/og-image.png`
          : `${config.paymentLinkUI}/static/og-not-found-image.png`,
      description:
        pageProps?.userData?.id && pageProps?.invoiceData?.success
          ? identifierText + 'Acesse o link para efeturar o pagamento'
          : 'Por favor, solicite uma nova cobrança',
    }
    return (
      <>
        <Head>
          <title>{meta.title}</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
          <meta name="theme-color" content={theme.palette.background.default} />
          <meta content={meta.title} name="twitter:title" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="@infinitepay" name="twitter:site" />
          <meta content="@infinitepay" name="twitter:creator" />
          <meta content={meta.title} name="twitter:text:title" />
          <meta content={meta.image} property="twitter:image" />
          <meta content={meta.title} property="og:title" />
          <meta content="website" property="og:type" />
          <meta content={meta.image} property="og:image" />
          <meta content="InfinitePay" property="og:site_name" />
          <meta content={meta.description} property="og:description" />
          <meta content={meta.description} name="twitter:description" />
          <link rel="shortcut icon" href="/static/favicon.png" />
          <link rel="icon" type="image/png" href="/static/favicon.png" />
          {this.state.mounted && <script type="text/javascript" src={cyberSourceUrl} async />}
        </Head>
        <Script id="clarity_tag" strategy="beforeInteractive">
          {`
              (function(c,l,a,r,i,t,y){
                c[a] = c[a] || function () { (c[a].q = c[a].q || 
                  []).push(arguments) };
                  t=l.createElement(r);
                  t.async=1;
                  t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];
                  y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "${CLARITY_ID}");
            `}
        </Script>
        <Script async src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`} />
        <Script id="gtag_tag" strategy="beforeInteractive">
          {`
                  window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${GTM_ID}', {page_path: window.location.pathname});
                `}
        </Script>
        <ReduxProvider store={store}>
          <PaymentContextProvider
            values={{
              userData: pageProps?.userData,
              invoiceData: pageProps?.invoiceData?.success ? pageProps?.invoiceData.data : null,
              accept: pageProps?.accept,
              params,
              sessionId,
              ...this.state,
            }}
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Layout>
                <Component router={router} {...pageProps} />
              </Layout>
              <div id="recaptcha-container" />
            </ThemeProvider>
          </PaymentContextProvider>
        </ReduxProvider>
      </>
    )
  }
}

export default withRedux(createStore)(withRouter(InfinitePaymentLinkApp))
